import {faArrowLeft, faBan, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon}            from '@fortawesome/react-fontawesome';

import {forbidExtraProps}     from 'airbnb-prop-types';
import {serverTimestamp,ref, push, update as dbUpdate, set}    from 'firebase/database';
import update                 from 'immutability-helper';
import difference             from 'lodash/difference';
import forEach                from 'lodash/forEach';
import isEmpty                from 'lodash/isEmpty';
import some                   from 'lodash/some';
import {marked}               from 'marked';
import PropTypes              from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';
import Alert                  from 'react-bootstrap/Alert';
import Button                 from 'react-bootstrap/Button';
import ButtonToolbar          from 'react-bootstrap/ButtonToolbar';
import Card                   from 'react-bootstrap/Card';
import Col                    from 'react-bootstrap/Col';
import Container              from 'react-bootstrap/Container';
import Form                   from 'react-bootstrap/Form';
import FormControl            from 'react-bootstrap/FormControl';
import Modal                  from 'react-bootstrap/Modal';
import ProgressBar            from 'react-bootstrap/ProgressBar';
import Row                    from 'react-bootstrap/Row';

import {connect}            from 'react-redux';
import Select               from 'react-select';
import {bindActionCreators} from 'redux';

import {subscribeToTags}      from '../actions/actionCreators';
import {app, database}        from '../base';
import Blueprint              from '../Blueprint';
import noImageAvailable       from '../gif/No_available_image.gif';
import generateTagSuggestions from '../helpers/generateTagSuggestions';
import * as propTypes         from '../propTypes';
import * as selectors         from '../selectors';

import PageHeader          from './PageHeader';
import TagSuggestionButton from './TagSuggestionButton';

const renderer = new marked.Renderer();
renderer.table = (header, body) => `<table class="table table-striped table-bordered">
<thead>
${header}</thead>
<tbody>
${body}</tbody>
</table>
`;
renderer.image = (href, title, text) =>
	`<img src="${href}" alt="${text}" class="img-responsive">`;

marked.use({
	renderer,
	gfm       : true,
	tables    : true,
	breaks    : false,
	pedantic  : false,
	sanitize  : false,
	smartLists: true,
	mangle    : false,
	headerIds : false,
});

const emptyTags = [];

const imgurHeaders = {
	'Accept'       : 'application/json',
	'Content-Type' : 'application/json',
	'Authorization': 'Client-ID 46a3f144b6a0882',
};

const initialState = {
	renderedMarkdown        : '',
	submissionErrors        : [],
	submissionWarnings      : [],
	uploadProgressBarVisible: false,
	uploadProgressPercent   : 0,
	blueprint               : {
		title              : '',
		descriptionMarkdown: '',
		blueprintString    : '',
		imageUrl           : '',
	},
};

const Create = ({
	user,
	subscribeToTags,
	tags,
	match,
	location,
	history,
	staticContext,
}) =>
{
	const [state, setState] = useState(initialState);
	const [parsedBlueprint, setParsedBlueprint] = useState(null);
	const [v15Decoded, setV15Decoded] = useState(null);

	const parseBlueprint = useCallback((blueprintString) =>
	{
		try
		{
			return new Blueprint(blueprintString);
		}
		catch (ignored)
		{
			console.log('Create.parseBlueprint', {ignored});
			return undefined;
		}
	}, []);

	const cacheBlueprintState = useCallback((blueprint) =>
	{
		if (blueprint)
		{
			const newBlueprint = {
				...blueprint,
				tags: blueprint.tags || emptyTags,
			};

			const renderedMarkdown = marked(blueprint.descriptionMarkdown);
			const parsedBp = parseBlueprint(blueprint.blueprintString);
			const decoded = parsedBp ? parsedBp.getV15Decoded() : null;

			setState(prevState => ({
				...prevState,
				blueprint: newBlueprint,
				renderedMarkdown,
			}));

			setParsedBlueprint(parsedBp);
			setV15Decoded(decoded);
		}
	}, [parseBlueprint]);

	useEffect(() =>
	{
		subscribeToTags();
		const localStorageRef = localStorage.getItem('factorio-blueprint-create-form');
		if (localStorageRef)
		{
			const blueprint = JSON.parse(localStorageRef);
			cacheBlueprintState(blueprint);
		}
	}, [subscribeToTags, cacheBlueprintState]);

	useEffect(() =>
	{
		localStorage.setItem('factorio-blueprint-create-form', JSON.stringify(state.blueprint));
	}, [state.blueprint]);

	const handleDismissError = useCallback(() =>
	{
		setState(prevState => ({
			...prevState,
			submissionErrors: [],
		}));
	}, []);

	const handleDismissWarnings = useCallback(() =>
	{
		setState(prevState => ({
			...prevState,
			submissionWarnings: [],
		}));
	}, []);

	const handleDescriptionChanged = useCallback((event) =>
	{
		const descriptionMarkdown = event.target.value;
		const renderedMarkdown = marked(descriptionMarkdown);
		setState(prevState => ({
			...prevState,
			renderedMarkdown,
			blueprint: {
				...prevState.blueprint,
				descriptionMarkdown,
			},
		}));
	}, []);

	const handleChange = useCallback((event) =>
	{
		const {name, value} = event.target;

		if (name === 'blueprintString')
		{
			const newParsedBlueprint = parseBlueprint(value);
			const newV15Decoded = newParsedBlueprint ? newParsedBlueprint.getV15Decoded() : null;

			setParsedBlueprint(newParsedBlueprint);
			setV15Decoded(newV15Decoded);
		}

		setState(prevState => ({
			...prevState,
			blueprint: {
				...prevState.blueprint,
				[name]: value,
			},
		}));
	}, [parseBlueprint]);

	const processStatus = useCallback((response) =>
	{
		if (response.status === 200 || response.status === 0)
		{
			return Promise.resolve(response);
		}
		return Promise.reject(new Error(response.statusText));
	}, []);

	const handleImgurError = useCallback((error) =>
	{
		console.error(error.message ? error.message : error);
	}, []);

	const handleFirebaseStorageError = useCallback((error) =>
	{
		console.error('Image failed to upload.', {error});
		setState(prevState => ({
			...prevState,
			submissionErrors        : ['Image failed to upload.'],
			uploadProgressBarVisible: false,
		}));
	}, []);

	const handleUploadProgress = useCallback((snapshot) =>
	{
		const uploadProgressPercent = Math.trunc(snapshot.bytesTransferred / snapshot.totalBytes * 100);
		setState(prevState => ({
			...prevState,
			uploadProgressPercent,
		}));
	}, []);

	const someHaveNoName = useCallback((blueprintBook) =>
	{
		return some(
			blueprintBook.blueprints,
			(eachEntry) =>
			{
				if (eachEntry.blueprint_book) return someHaveNoName(eachEntry.blueprint_book);
				if (eachEntry.blueprint) return isEmpty(eachEntry.blueprint.label);
				return false;
			});
	}, []);

	const validateInputs = useCallback(() =>
	{
		const submissionErrors = [];
		const {blueprint} = state;
		if (!blueprint.title)
		{
			submissionErrors.push('Title may not be empty');
		}
		else if (blueprint.title.trim().length < 10)
		{
			submissionErrors.push('Title must be at least 10 characters');
		}

		if (!blueprint.descriptionMarkdown)
		{
			submissionErrors.push('Description Markdown may not be empty');
		}
		else if (blueprint.descriptionMarkdown.trim().length < 10)
		{
			submissionErrors.push('Description Markdown must be at least 10 characters');
		}

		if (!blueprint.blueprintString)
		{
			submissionErrors.push('Blueprint String may not be empty');
		}
		else if (blueprint.blueprintString.trim().length < 10)
		{
			submissionErrors.push('Blueprint String must be at least 10 characters');
		}

		const badRegex = /^https:\/\/imgur\.com\/(a|gallery)\/[a-zA-Z0-9]+$/;
		if (badRegex.test(blueprint.imageUrl))
		{
			submissionErrors.push('Please use a direct link to an image like https://imgur.com/{id}. Click on the "Copy Link" button on the Imgur image page.');
		}
		else
		{
			const goodRegex1 = /^https:\/\/i\.imgur\.com\/[a-zA-Z0-9]+\.[a-zA-Z0-9]{3,4}$/;
			const goodRegex2 = /^https:\/\/imgur\.com\/[a-zA-Z0-9]+$/;
			if (!goodRegex1.test(blueprint.imageUrl) && !goodRegex2.test(blueprint.imageUrl))
			{
				submissionErrors.push('Please use a direct link to an image like https://imgur.com/{id} or https://i.imgur.com/{id}.{ext}');
			}
		}

		return submissionErrors;
	}, [state]);

	const validateWarnings = useCallback(() =>
	{
		const submissionWarnings = [];

		if (isEmpty(state.blueprint.tags))
		{
			submissionWarnings.push('The blueprint has no tags. Consider adding a few tags.');
		}

		const blueprint = new Blueprint(state.blueprint.blueprintString.trim());
		if (isEmpty(blueprint.decodedObject))
		{
			submissionWarnings.push('Could not parse blueprint.');
			return submissionWarnings;
		}

		if (blueprint.isV14())
		{
			submissionWarnings.push('Blueprint is in 0.14 format. Consider upgrading to the latest version.');
		}

		if (blueprint.isBlueprint() && v15Decoded && isEmpty(v15Decoded.blueprint.label))
		{
			submissionWarnings.push('Blueprint has no name. Consider adding a name.');
		}
		if (blueprint.isBlueprint() && v15Decoded && isEmpty(v15Decoded.blueprint.icons))
		{
			submissionWarnings.push('The blueprint has no icons. Consider adding icons.');
		}

		if (blueprint.isBook() && v15Decoded && someHaveNoName(v15Decoded.blueprint_book))
		{
			submissionWarnings.push('Some blueprints in the book have no name. Consider naming all blueprints.');
		}

		return submissionWarnings;
	}, [state.blueprint, v15Decoded, someHaveNoName]);

	const actuallyCreateBlueprint = useCallback(async () =>
	{
		const imageUrl = state.blueprint.imageUrl;

		const regexCheck = pattern => imageUrl.match(pattern);
		const regexPatterns = {
			imgurUrl1: /^https:\/\/imgur\.com\/([a-zA-Z0-9]{7})$/,
			imgurUrl2: /^https:\/\/i\.imgur\.com\/([a-zA-Z0-9]+)\.[a-zA-Z0-9]{3,4}$/,
		};

		const matches = Object.values(regexPatterns).map(pattern => regexCheck(pattern)).filter(Boolean);
		if (matches.length <= 0)
		{
			console.log('Create.actuallyCreateBlueprint error in imageUrl', {imageUrl});
			return;
		}

		const match = matches[0];
		const imgurId = match[1];
		const image = {
			id  : imgurId,
			type: 'image/png',
		};

		const blueprint = {
			...state.blueprint,
			author: {
				userId: user.uid,
			},
			authorId         : user.uid,
			createdDate      : serverTimestamp(),
			lastUpdatedDate  : serverTimestamp(),
			favorites        : {},
			numberOfFavorites: 0,
			image,
		};

		const blueprintSummary = {
			imgurId          : blueprint.image.id,
			imgurType        : blueprint.image.type,
			title            : blueprint.title,
			numberOfFavorites: blueprint.numberOfFavorites,
			lastUpdatedDate  : serverTimestamp(),
		};

		try
		{
			const blueprintsRef = ref(database, '/blueprints');
			const newBlueprintRef = push(blueprintsRef, blueprint);
			const newBlueprintKey = newBlueprintRef.key;

			const updates = {};

			updates[`/users/${user.uid}/blueprints/${newBlueprintKey}`] = true;
			updates[`/blueprintSummaries/${newBlueprintKey}`] = blueprintSummary;
			updates[`/blueprintsPrivate/${newBlueprintKey}/imageUrl`] = imageUrl;

			forEach(blueprint.tags, (tag) =>
			{
				updates[`/byTag/${tag}/${newBlueprintKey}`] = true;
			});

			await dbUpdate(ref(database), updates);

			setState(initialState);
			history.push(`/view/${newBlueprintKey}`);
		}
		catch (e)
		{
			console.log(e);
			return;
		}
	}, [history, state.blueprint, user]);

	const handleCreateBlueprint = useCallback((event) =>
	{
		event.preventDefault();

		const submissionErrors = validateInputs();

		if (submissionErrors.length > 0)
		{
			setState(prevState => ({
				...prevState,
				submissionErrors,
			}));
			return;
		}

		const submissionWarnings = validateWarnings();
		if (submissionWarnings.length > 0)
		{
			setState(prevState => ({
				...prevState,
				submissionWarnings,
			}));
			return;
		}

		actuallyCreateBlueprint();
	}, [validateInputs, validateWarnings, actuallyCreateBlueprint]);

	const handleForceCreateBlueprint = useCallback((event) =>
	{
		event.preventDefault();

		const submissionErrors = validateInputs();
		if (submissionErrors.length > 0)
		{
			setState(prevState => ({
				...prevState,
				submissionErrors,
			}));
			return;
		}

		actuallyCreateBlueprint();
	}, [validateInputs, actuallyCreateBlueprint]);

	const handleCancel = useCallback(() =>
	{
		localStorage.removeItem('factorio-blueprint-create-form');
		history.push('/blueprints');
	}, [history]);

	const handleTagSelection = useCallback((selectedTags) =>
	{
		const tags = selectedTags.map(each => each.value);
		setState(prevState => ({
			...prevState,
			blueprint: {
				...prevState.blueprint,
				tags,
			},
		}));
	}, []);

	const addTag = useCallback((tag) =>
	{
		setState(prevState => update(prevState, {
			blueprint: {tags: {$push: [tag]}},
		}));
	}, []);

	const renderPreview = useCallback(() =>
	{
		if (!state.blueprint.imageUrl)
		{
			return <div />;
		}

		return (
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm='2'>
					{'Attached screenshot'}
				</Form.Label>
				<Col sm={10}>
					<Card className='mb-2 mr-2' style={{width: '14rem', backgroundColor: '#1c1e22'}}>
						<Card.Img variant='top' src={state.blueprint.imageUrl || noImageAvailable} />
						<Card.Title className='truncate'>
							{state.blueprint.title}
						</Card.Title>
					</Card>
				</Col>
			</Form.Group>
		);
	}, [state.blueprint]);

	if (!user)
	{
		return (
			<div className='p-5 rounded-lg jumbotron'>
				<h1 className='display-4'>
					{'Create a Blueprint'}
				</h1>
				<p className='lead'>
					{'Please log in with Google or GitHub in order to add a Blueprint.'}
				</p>
			</div>
		);
	}

	const {blueprint} = state;
	const allTagSuggestions = generateTagSuggestions(
		state.blueprint.title,
		parsedBlueprint,
		v15Decoded,
	);
	const unusedTagSuggestions = difference(allTagSuggestions, state.blueprint.tags || []);

	return (
		<>
			<Modal show={state.uploadProgressBarVisible}>
				<Modal.Header>
					<Modal.Title>
						Image Upload Progress
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ProgressBar
						now={state.uploadProgressPercent}
						label={`${state.uploadProgressPercent}%`}
						variant='warning'
						className='text-light'
					/>
				</Modal.Body>
			</Modal>
			<Modal show={!isEmpty(state.submissionWarnings)}>
				<Modal.Header>
					<Modal.Title>
						{'Submission warnings'}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						{'The following warnings occurred while submitting your blueprint. Do you want to save it anyway or go back and make further edits?'}
					</p>
					<ul>
						{
							state.submissionWarnings.map(submissionWarning => (
								<li key={submissionWarning}>
									{submissionWarning}
								</li>
							))
						}
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<ButtonToolbar>
						<Button variant='danger' type='button' onClick={handleForceCreateBlueprint}>
							<FontAwesomeIcon icon={faSave} size='lg' />
							{' Save'}
						</Button>
						<Button variant='primary' type='button' onClick={handleDismissWarnings}>
							<FontAwesomeIcon icon={faArrowLeft} size='lg' />
							{' Go back'}
						</Button>
					</ButtonToolbar>
				</Modal.Footer>
			</Modal>

			<Container>
				<Row>
					{
						state.submissionErrors.length > 0 && <Alert
							variant='danger'
							className='alert-fixed'
							dismissible
							onClose={handleDismissError}
						>
							<h4>
								{'Error submitting blueprint'}
							</h4>
							<ul>
								{
									state.submissionErrors.map(submissionError => (
										<li key={submissionError}>
											{submissionError}
										</li>
									))
								}
							</ul>
						</Alert>
					}
				</Row>
				<PageHeader title='Create a new Blueprint' />
				<Row>
					<Form className='w-100'>
						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Title'}
							</Form.Label>
							<Col sm={10}>
								<FormControl
									autoFocus
									type='text'
									name='title'
									placeholder='Title'
									value={blueprint.title}
									onChange={handleChange}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Description '}
								<a href='https://guides.github.com/features/mastering-markdown/'>
									{'[Tutorial]'}
								</a>
							</Form.Label>
							<Col sm={10}>
								<FormControl
									as='textarea'
									placeholder='Description (plain text or *GitHub Flavored Markdown*)'
									value={blueprint.descriptionMarkdown}
									onChange={handleDescriptionChanged}
									style={{minHeight: 200}}
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Description (Preview)'}
							</Form.Label>
							<Col sm={10}>
								<Card>
									<div
										style={{minHeight: 200}}
										dangerouslySetInnerHTML={{__html: state.renderedMarkdown}}
									/>
								</Card>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Blueprint String'}
							</Form.Label>
							<Col sm={10}>
								<FormControl
									className='blueprintString'
									as='textarea'
									name='blueprintString'
									placeholder='Blueprint String'
									value={blueprint.blueprintString}
									onChange={handleChange}
								/>
							</Col>
						</Form.Group>

						{
							unusedTagSuggestions.length > 0
							&& <Form.Group as={Row} className='mb-3'>
								<Form.Label column sm='2'>
									{'Tag Suggestions'}
								</Form.Label>
								<Col sm={10}>
									<ButtonToolbar>
										{
											unusedTagSuggestions.map(tagSuggestion => (
												<TagSuggestionButton
													key={tagSuggestion}
													tagSuggestion={tagSuggestion}
													addTag={addTag}
												/>
											))
										}
									</ButtonToolbar>
								</Col>
							</Form.Group>
						}

						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Tags'}
							</Form.Label>
							<Col sm={10}>
								<Select
									value={(state.blueprint.tags || []).map(value => ({value, label: value}))}
									options={tags.map(value => ({value, label: value}))}
									onChange={handleTagSelection}
									isMulti
									placeholder='Select at least one tag'
								/>
							</Col>
						</Form.Group>

						<Form.Group as={Row} className='mb-3'>
							<Form.Label column sm='2'>
								{'Imgur URL'}
							</Form.Label>
							<Col sm={10}>
								<FormControl
									autoFocus
									type='text'
									name='imageUrl'
									placeholder='https://imgur.com/kRua41d'
									value={blueprint.imageUrl}
									onChange={handleChange}
								/>
							</Col>
						</Form.Group>

						{renderPreview()}

						<Form.Group as={Row} className='mb-3'>
							<Col sm={{span: 10, offset: 2}}>
								<ButtonToolbar>
									<Button
										type='button'
										variant='warning'
										size='lg'
										onClick={handleCreateBlueprint}
									>
										<FontAwesomeIcon icon={faSave} size='lg' />
										{' Save'}
									</Button>
									<Button
										type='button'
										size='lg'
										onClick={handleCancel}
									>
										<FontAwesomeIcon icon={faBan} size='lg' />
										{' Cancel'}
									</Button>
								</ButtonToolbar>
							</Col>
						</Form.Group>
					</Form>
				</Row>
			</Container>
		</>
	);
};

Create.propTypes = forbidExtraProps({
	user           : propTypes.userSchema,
	subscribeToTags: PropTypes.func.isRequired,
	tags           : PropTypes.arrayOf(PropTypes.string).isRequired,
	match          : PropTypes.shape(forbidExtraProps({
		params : PropTypes.shape(forbidExtraProps({})).isRequired,
		path   : PropTypes.string.isRequired,
		url    : PropTypes.string.isRequired,
		isExact: PropTypes.bool.isRequired,
	})).isRequired,
	location     : propTypes.locationSchema,
	history      : propTypes.historySchema,
	staticContext: PropTypes.shape(forbidExtraProps({})),
});

const mapStateToProps = storeState => ({
	user      : selectors.getFilteredUser(storeState),
	tags      : selectors.getTags(storeState),
	tagOptions: selectors.getTagsOptions(storeState),
});

const mapDispatchToProps = (dispatch) =>
{
	const actionCreators = {subscribeToTags};
	return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
